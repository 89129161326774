$base-color: #f5f6f8;
$link-color:#1D4599;
$dark-head:#000000;
$light-head:#CBCBCB;
$base-shadow: #e6e6e6;
$theme-color:#1D4599;
$secondary-color:#888888;
$theme-border: #1D4599; 
$base-bg-1: #e8eaec;
$base-bg-2: #d1d4d8;
// $main-btn-bg:#1D4599;
$main-btn-bg:#0E730B;
$main-btn-font:#FFFFFF;
$secondary-bg: #f9f9f9;
$base-color-darker: #f2f3f6;
$base-color-darker-2: #e7e9ee;
$bg-white:#FFFFFF;

$default-bg: #ffffff;
$black: #000000;
$grey: grey;

$primary-color: #e0004d;
$primary-light: #ff0e61;
$primary-light-2: #ffffffc1;
$primary-dark: #d9004b;
$primary-dark-2: #b3003e;

$box-color: whitesmoke;
$box__border-color: #cfcfcf;