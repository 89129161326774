@import "Input";
@import "Colors";
@import "toastr";
@import "fonts/_fonts.scss";
$wt-400: 400;

.container-fluid {
  height: 100vh;
}

.inputfield {
  @extend %input;
  width: 100%;
  border-radius: 8px;
  height: 50px;
}

.ant-upload-list-picture .ant-upload-list-item {
  border-radius: 8px;
}

.inputfield .ant-input {
  font-size: 16px;
}

.preview-only .ant-input {
  font-size: 16px;
}

.inputfield:focus {
  box-sizing: border-box;
  background: $default-bg;
  border: 1px solid $theme-border;
  border-radius: 6px;
  outline: 1px solid transparent;
}

.container-section {
  width: 100%;
  background: $default-bg;
  box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 40px 7%;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.mainBtn {
  width: 150px;
  height: 55px;
  background: $main-btn-bg;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  color: $main-btn-font;
  border: 1px solid transparent;
}

.requestLinkBtn {
  width: 240px;
  height: 55px;
  background: $main-btn-bg;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  color: $main-btn-font;
  border: 1px solid transparent;
}

.privilege-Btn {
  width: 130px;
  height: 35px;
  font-size: 10px;
  background: $main-btn-bg;
  border-radius: 6px;
  font-size: 16px;
  color: $main-btn-font;
  border: 1px solid transparent;
}

.mainBtn:hover {
  color: $main-btn-font;
  background: $main-btn-bg;
}

.mainBtn .anticon svg {
  margin-top: -7px;
}

.servicesBackBtn {
  width: 165px;
  height: 50px;
  background:#DDE9DC;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0E730B  !important;
}

.servicesBackBtn:hover {
border-color: #0E730B;
}

.maintenanceListing .ant-btn[disabled] {
  background-color: grey;
}
.servicesMainBtn {
  background: $main-btn-bg;

  color: $main-btn-font;
  border: 1px solid transparent;
  width: 165px;
  height: 50px;
  border-radius: 4px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.servicesMainBtn:hover {
  color: $main-btn-font;
  background: #045302;
  border-color: #045302;
}

.servicesMainBtn .anticon svg {
  margin-top: -7px;
}

.anticon svg {
  margin-top: unset;
}

.secBtn {
  height: 32px;
  width: 180px;
  color: $main-btn-font;
  border: 1px solid transparent;
  border-radius: 6px;
  background: #42b72a;
}

.ant-form-item-explain-error {
  margin-bottom: 10px;
}

.ant-form-item-feedback-icon {
  margin-top: -6px;
}

.default-hr {
  border: 1px solid #cbcbcb;
}

.default-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  align-items: center;
  color: #0E730B;
  text-decoration: none;
}

.default-link:hover {
  color: #045302;
}

.logo-default {
  width: 300px;
  height: 200px;
  object-fit: contain;
}

.default-heading {
  font-weight: $wt-400;
  font-size: 30px;
  line-height: 35px;
  color: $dark-head;
}

.banner-main {
  padding: 30px;
  position: relative;
  min-height: 200px;
  background-color: #fafafa;
  border-radius: 6px;

  background: #ffffff;
  box-shadow: 0px 0px 40px 2px rgb(0 0 0 / 8%);
  width: 80%;
  height: auto;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.reschedule .reschedule-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #363636;
}

.rdp-button[aria-disabled="true"] {
  opacity: 0.55;
}

.reschedule .ant-card-meta-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  white-space: pre-wrap;
  color: #000000;
}

.reschedule .preferredDate {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.second-head {
  font-weight: $wt-400;
  font-size: 11px;
  line-height: 10px;
  color: $light-head;
}

.mtt-20-per {
  margin-top: 20%;
}

.mtt-10-per {
  margin-top: 10%;
}

.mtt-15-per {
  margin-top: 15%;
}

.mtt-11-per {
  margin-top: 11%;
}

.mtt-20 {
  margin-top: 20px;
}

.mtt-12 {
  margin-top: 12px;
}

.mtt-10 {
  margin-top: 10px;
}

.ant-radio-button-wrapper {
  height: 40px;
  text-align: center;
  color: #0E730B;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  background: #DDE9DC;
  width: 165px;
  border-radius: 4px !important;
  padding-top: 5px;
  cursor: pointer;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout-background-content {
  margin: 10px 20px;
  padding: 20px;
}

.site-layout .site-layout-background {
  background: $bg-white;
}

.site-layout .site-layout-background-inner {
  margin: 10px 20px;
  padding: 20px;
  min-height: 280px;
}

.ant-layout-sider-trigger {
  bottom: unset;
}

.ant-layout-sider {
  background: $secondary-bg;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: $secondary-bg;
}

.ant-layout-sider-trigger {
  background: $secondary-bg;
  color: #888888;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #0E730B1A;
  color: $theme-color;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: $theme-color;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: $theme-color;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: #888888;
}

.ant-menu-dark .ant-menu-item:hover {
  background-color: #e1e9fa;
  color: $secondary-color;
}

.sidebar-logo {
  height: 60px;
  width: 160px;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

.sidebar-logo-icon {
  height: 100px;
  width: 60px;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

.dp-none {
  display: none;
}

.ant-layout-sider {
  min-height: 100vh;
}

nav {
  display: grid;
  grid-template-columns: 8fr 1fr;
  align-items: center;
  background-color: $bg-white;
  padding: 0px 50px;
}

.logo {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #363636;
}

.form-text-font-design {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #474747;
  align-self: center;
  white-space: nowrap;
}

.nav-links {
  display: flex;
  justify-content: end;
  // margin-top: 14px;
  margin-right: -25px;
}

.nav-links li {
  list-style: none;
  margin: 0px 6px;
}

.nav-links-icons-right {
  margin-left: 12px;
}

.nav-links li:hover {
  opacity: 0.9;
  cursor: pointer;
}

.nav-links a {
  color: rgb(226, 226, 226);
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 14px;
}

.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px;
  transition: all 0.3s ease;
}

.nav-active {
  transform: translateX(0%);
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.toggle .line1 {
  transform: rotate(-40deg) translate(-5px, 6px);
}

.toggle .line2 {
  transform: rotate(90deg) translate(0px, 0px);
}

.toggle .line3 {
  transform: rotate(35deg) translate(-5px, -6px);
}

.ant-layout-header {
  padding: 0px;
  height: 75px;
}

.ant-layout {
  background: $bg-white;
}

.nav-links-icons {
  font-size: 22px;
  color: #939393;
}

.search-btn {
  background-color: #1d4599;
  color: white;
  border-radius: 7px;
  height: 42px;
}

.ant-btn-icon-only.ant-btn-lg {
  width: 35px;
  height: 35px;
  padding: 4.9px 5px;
  font-size: 12px;
  border-radius: 6px;
}

.ant-avatar-image {
  background: transparent;
  border: 1px solid #aba6a6;
  height: 40px;
  width: 40px;
}

.align-right {
  text-align: right;
}

.LgBtn {
  height: 35px;
  background: #0E730B;
  border-radius: 6px;
  color: #ffffff;
  border-style: none;
  box-shadow: none;
}

.greenBtn {
  height: 35px;
  width: 220px;
  margin-left: 10px;
  background: #d0f4d9;
  color: #3d9611;
  justify-content: center;
  border-radius: 6px;
  color: #ffffff;
  border: 1px solid transparent;
  box-shadow: none;
}

.input-icon-field {
  height: 12px;
}

.input-icon-field-twenty {
  height: 20px;
}

.step-icon-size {
  height: 23px;
}

.ant-table-thead > tr > th {
  font-weight: 600;
  background: white;
  text-align: center;
  font-size: 16px;
}

.tabled-div .status-dropdown {
  border-radius: 6px !important;
}

.ant-select-selector {
  border-radius: 6px !important;
}

.org-col-icon {
  height: 1.5rem;
}

.actions-col-btn {
  height: 7px;
}

.building-img-card {
  background: #e1e9fa;
  border-radius: 6px;
  height: 43px;
  width: 47px;
  text-align: center;
  padding: 10px 0px 0px 0px;
  margin-bottom: 5px;
}

.sm-title-card {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 10px;
  color: #000000;
}

.reschedule .sm-title-card {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 24px;
  line-height: 10px;
  color: #000000;
}

.reschedule .desc-grey {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  color: #787878;
}

.dayPicker .rdp-head_cell {
  font-size: 1.1em;
  font-weight: 600;
  text-transform: capitalize;
}

.desc-grey-font-12 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;

  display: flex;
  align-items: center;

  color: #9e9e9e;
}

.ant-card-bordered {
  width: 260px;
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 2px rgb(0 0 0 / 8%);
  border-radius: 6px;
}

.rescheduleDropdown {
  width: 130px;
  border-radius: 6px;
}

.tenant-dropdown {
  width: 150px;
  border-radius: 6px;
}
.ant-card-body {
  padding: 24px;
}

.site-card-wrapper .ant-card-body {
  padding: 10px;
}

.butler-img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #efefef;
}

.header-heading {
  display: flex;
  justify-content: space-between;
}

.nav-links .ol,
ul {
  padding-left: 1rem;
}

.steps-content {
  padding: 30px;
  position: relative;
  min-height: 200px;
  background-color: #fafafa;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 40px 2px rgb(0 0 0 / 8%);
}

.addServiceSteps .steps-content {
  height: 105% !important;
}

.addServiceSteps .steps-action-one {
  margin-top: 0px;
  right: 4px;
}

.edit-action {
  height: 20px;
  margin-bottom: 5px;
}

.addVisitor .steps-action-one {
  margin-top: 0px;
  position: absolute;
  top: 20px;
  right: 0px;
}

.helpingText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(133, 133, 133, 0.8);
}

.steps-action-one {
  margin-top: 10px;
  position: absolute;
  top: 15px;
  bottom: 10px;
  right: 20px;
}

.steps-action {
  position: absolute;
  bottom: 20px;
  right: 20px;
  justify-content: space-between;
}

.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 30px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
  background: #ffffff;
  box-shadow: 0px 0px 40px 2px rgb(0 0 0 / 8%);
  border-radius: 6px;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #0E730B;
  height: 15px !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #cfcaca;
  height: 15px !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #cfcaca;
  height: 15px !important;
}

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  height: 50px;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 50px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

.addServiceForm
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 35px;
  border-radius: 6px;
  padding-bottom: 5px;
  padding-top: 4px;
}

.steps-content .ant-select {
  font-size: 16px;
}

.stepNumber {
  position: absolute;
  bottom: 20px;
}

.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  background: #e1e9fa;
  border: 2px dashed #1d4599;
  border-radius: 14px;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  background: #e1e9fa;
  border: 2px dashed #1d4599;
  height: 185px;
  border-radius: 14px;
  width: 45%;
}

.bannerUpload {
  margin-top: 30px;
}

.bannerUpload .ant-upload.ant-upload-select-picture-card {
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  background: #DDE9DC;
  border: 2px dashed #0E730B;
  border-radius: 14px;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  margin-top: 10px;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  background: #DDE9DC;
  border: 2px dashed #0E730B;
  height: 115px;
  border-radius: 14px;
  width: 45%;
}

.bannerUpload .ant-upload-list-picture-card .ant-upload-list-item {
  height: 115px;
  border-radius: 10px;
  width: 100%;
  margin: 0;
  margin-top: 10px;
}

.bannerUpload .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 45%;
  height: 126px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}

.bannerUpload .titleHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 10px;
  /* identical to box height, or 48% */

  display: flex;
  align-items: center;

  color: #000000;
}

.ant-upload {
  font-size: 40px;
}

.secondaryBtn {
  background: #e1e9fa;
  border-radius: 4px;

  width: 180px;
  height: 32px;
  color: #1d4599;
}

.checkText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 21px;
  color: #737373;
  margin: 15px;
  margin-left: 0px;
}

.congrats .ant-modal-content {
  text-align: center;
}

.custom-modal-footer {
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.custom-modal-footer .ant-upload-list-item-info {
  display: none;
}

.modal-btn {
  height: 45px;
  width: 175px;
  background-color: #0E730B;
  border-radius: 6px;
  color: #ffffff;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
}

// .modal-btn:hover {
//   color: #ffffff !important;
// }

.modal-btn-span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
}

// .modal-btn:hover {
//   background-color: #e1e9fa;
//   color: #1d4599;
// }

.modal-header-text {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  color: #272727;
  text-align: center;
}

.drag-upload-img-div {
  background: #DDE9DC;
  border-radius: 6px;
  height: 450px;
  display: grid;
  grid-template-rows: 4fr 2fr;
  justify-content: center;
  align-items: center;
}

.drag-caption {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0E730B;
  margin: 20px;
}

.text-bw-drag-and-upload {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #0E730B;
}

.modal-upload-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 250px;
  height: 32px;
  background: #ffffff;
  border-radius: 6px;
  color: #0E730B;
  border: 1px solid #0E730B;
}

.congrats-icon {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.big-text {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #000000;
}

.big-desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

.def-sm-Title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 10px;
  /* or 46% */

  display: flex;
  align-items: center;

  color: #000000;
}

.mbb-20 {
  margin-bottom: 20px;
}

.mbb-10 {
  margin-bottom: 10px;
}

.align-right {
  text-align: right;
}

.progressSelector
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #e1e9fa !important;
  color: #000000 !important;
  border: 1px solid lightblue;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 40px;
  border-radius: 6px;
  padding-top: 4px;

  .ant-select-selection-item {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
  }
}

.activeSelector
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #d0f4d9 !important;
  color: #000000 !important;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 40px;
  border-radius: 6px;
  padding-top: 4px;

  .ant-select-selection-item {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
  }
}

.InactiveSelector
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #f4d0d0 !important;
  color: #000000 !important;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 40px;
  border-radius: 6px;
  padding-top: 4px;

  .ant-select-selection-item {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
  }
}

.anticon-down {
  color: #000000 !important;
}

.pendingSelector
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  position: relative;
  background-color: #eaeaea !important;
  color: #000000 !important;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 40px;
  border-radius: 6px;
  padding-top: 4px;

  .ant-select-selection-item {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
  }
}

.ant-select-dropdown {
  width: 100px !important;
}

.Services-Input .ant-checkbox-group-item {
  margin-right: 8px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0px;
}

.Services-Input .ant-radio-button-wrapper {
  color: #0E730B;
  font-size: 13px;
  margin-left: 20px;
}

.statusField .statusInput {
  height: 50px;
  width: 42px;
  border: 1px solid #d1d8d1;
  padding: 10px 5px 10px 10px;
  border-radius: 7px 0 0 7px;
  border-right: none;
}

.statusField .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0 8px 8px 0 !important;
  border-left: none;
  padding: 5px 8px;
}

.statusField .ant-select-focused {
  border: none;
}

.Services-Input .ant-radio-button-wrapper:not(:first-child):before {
  display: none;
}

.addServiceForm .ant-form-item {
  margin: 0px 0px 0px 0px;
}

.addServiceForm .ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  /* min-height: 1px; */
  /* max-height: 19px; */
  padding: 3px;
  opacity: 1;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(133, 133, 133, 0.8);
}

.visitorForm .ant-form-item {
  margin: 0px 0px 0px 0px;
}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  color: #ffffff;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.85);
}

.visitorModel .ant-modal-content {
  text-align: left !important;
}

.visitorModel-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  /* identical to box height */

  color: #363636;
}

.preview-only .ant-upload-list-picture-card {
  margin-top: 0px;
}

.photosAndFacilities .ant-upload.ant-upload-select-picture-card {
  width: 150px;
  left: 663px;

  background: #e1e9fa;
  border: 2px dashed #1d4599;
  border-radius: 14px;
  height: 150px;
}

.photosAndFacilities .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 150px;
  height: 150px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  border-radius: 14px;
  padding: 0px;
}

.timeAvailability .ant-checkbox-group-item {
  margin-right: 8px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 5px;

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.mtt-10 {
  margin-top: 10px !important;
}

.mtt-20 {
  margin-top: 20px !important;
}

.selected-services .ant-checkbox-group-item {
  width: unset;
}

.action-menu .anticon {
  font-size: 30px;
  color: #c4c4c4;
}

.ant-dropdown {
  min-width: 100px !important;
}

.specialInstructions .ant-typography {
  margin-bottom: 0px;
}

.grey-defTxt {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #a8a8a8;
}

.site-input-group-wrapper .site-input-split {
  background-color: #fff;
}

.site-input-group-wrapper .site-input-right {
  border-left-width: 0;
}

.site-input-group-wrapper .site-input-right:hover,
.site-input-group-wrapper .site-input-right:focus {
  border-left-width: 1px;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right {
  border-right-width: 0;
}

.site-input-group-wrapper .ant-input-rtl.site-input-right:hover,
.site-input-group-wrapper .ant-input-rtl.site-input-right:focus {
  border-right-width: 1px;
}

.EditBtn {
  height: 40px;
  border-left: 1px solid transparent;
  color: #0e3dcb;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker:first-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-select-auto-complete:first-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-select:first-child
  > .ant-select-selector,
.ant-input-group.ant-input-group-compact > :first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  height: 40px;
}

.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker-focused:last-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker:last-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-select:last-child
  > .ant-select-selector,
.ant-input-group.ant-input-group-compact > :last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.EditForm .ant-select-selector {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.ant-input {
  border-radius: 8px;
}

.ant-pagination {
  display: flex;
  justify-content: center;
}

.ant-pagination-total-text {
  position: absolute;
  left: 233px;
}

.ant-pagination-options {
  position: absolute;
  right: 48px;
}

.maintenanceListing .ant-pagination-total-text {
  position: absolute;
  left: 240px !important;
}

.listing-card {
  width: 250px;
  border-radius: 10px;
}

.listing-image {
  height: 168px;
  width: 250px;
  border-radius: 10px !important;
}

.listing-body {
  display: flex;
  justify-content: space-between;
  padding: 10px 6px;
}

.listing-price {
  color: #0E730B;
  font-size: 16px;
  margin-top: -2px;
  white-space: nowrap;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  font-style: italic;
}

.normalPlaceholder .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  font-style: normal;
}

.addServiceForm .desc {
  padding: 5px;
  height: 120px;
  border-radius: 8px;
}

.ant-pagination-item-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination-item {
  border-radius: 4px;
  background-color: #ffffff;
  color: #0E730B;
}

.ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  color: #0E730B;
}

.ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  color: #0E730B;
}

.ant-pagination-item-active {
  border: 1px solid #0E730B;
}

.ant-pagination-item-active:hover {
  border: 1px solid #0E730B;
}

.mtt-min-20 {
  margin-top: -20px;
}

table {
  font-family: arial, sans-serif;
  width: 100%;
  border: none !important;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  border: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td .ant-table-cell-row-hover {
  background-color: #D0F4D9;
}

.profile-dropdown-menu-item {
  width: 260px;
  border-radius: 6px;
}

.notification-dropdown-menu-item {
  width: 440px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.notification-day-heading {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 5px 0px;
}

.notification-markRead {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 0px;
  text-decoration: none;
  color: #000000;
}

.notification-main {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.borderTop {
  border-top: 1px solid #e4e8ee;
  margin: 5px -0.6vw;
}

.notification-heading {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.notification-time {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #b1b1b1;
}

.notification-viewMore {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 5px 0px;
  text-decoration: none;
  color: #000000;
}

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #1d4599;
  border-color: #1d4599;
}

.pagination-div {
  width: 150%;
}

.first-pagination-area {
  display: flex;
  align-items: center;
}

.showing-records {
  font-size: 14px;
  color: #8c8c8c;
  line-height: 20px;
}

.second-pagination-area {
  display: flex;
  justify-content: flex-end;
}

.build-btn {
  display: flex;
  align-items: center;
}

.plus-white-icon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.add-new-building-btn {
  margin-top: 18px;
  font-family: "Roboto";
  font-weight: 500;
  text-shadow: none;
  font-size: 16px;
  line-height: 19px;
}

.ant-btn-primary:hover {
  background-color: #045302;
  border-color: #045302;
}

.ant-btn-primary:focus {
  border-color: #045302;
  background-color: #045302;

}

.servicesMainBtn:focus {
  background-color: #045302 !important;
  color: #ffffff;
}

.ant-btn-primary[disabled] {
  color: #ffffff;
}

.justify-content-space {
  justify-content: space-evenly;
}

.page-link {
  border-radius: 4px;
  background-color: #ffffff;
  color: #1d4599;
}

.table-divider {
  border: 1px solid #dadada;
  width: 100%;
  margin-top: -60px;
}

.site-form-item-icon {
  margin-left: 5px;
}

.ant-input-affix-wrapper > input.ant-input {
  padding-left: 7px;
}

.country-select {
  width: 100%;
}

.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: #1d4599;
}

.ant-radio-group-solid
  > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #0E730B;
  border-color: #0E730B;
  font-weight: 500;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1d4599;
}

.dashboard-widgets-icons {
  height: 43px;
}
.ant-input-group-addon:first-child {
  background-color: #0E730B;
  min-width: 11%;
}

.PhoneInputInput {
  border: none;
  outline: none;
  font-size: 16px;
}

.ReactFlagsSelect-module_selectValue__152eS {
  font-size: 16px;
  color: #8c8c8c;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
  border: 1px solid #cbcbcb;
  border-radius: 8px !important;
  height: 50px;
}

.ReactFlagsSelect-module_filterBox__3m8EU input {
  border: 1px solid #cbcbcb;
  border-radius: 6px;
}

.am-and-pm-div {
  width: 231px;
  display: flex;
  justify-content: space-between;
}

.react-tel-input .form-control {
  width: 100%;
  height: 40px;
  border-radius: 6px;
}

.wd-phone .PhoneInputCountrySelect {
  width: 0;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.vt-btn {
  padding: 0px 7px;
  background: #e1e9fa;
  border-radius: 6px;
  border: 1px solid transparent;
}

.divider-border-radius {
  border: 1px solid #dadada;
}

.profile-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 10px;
  color: #000000;
}

.profile-menu-drawer {
  border: 0.5px solid #c6c6c6;
  margin: 0px;
}

.profile-menu {
  height: 40px;
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 10px;
  color: #000000;
}

.editDropdown {
  border-radius: 6px;
}

.dp-grid-tenant {
  display: grid;
  grid-template-columns: 3fr 6fr;
}

.card {
  width: 100%;
}

.italic-sub-heading {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 10px;
  color: #8c8c8c;
}

.list-heading {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 22px;
  margin-top: 5px;
  line-height: 26px;
  color: #363636;
}

.sub-heading {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  color: #787878;
}

.ant-dropdown-menu-title-content > a {
  text-decoration: none;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan]):before {
  display: none;
}

.table-pagination {
  word-wrap: normal;
}

.table-pagination .ant-pagination-total-text {
  position: absolute;
  left: 26px;
}

.listing-table-pagination .ant-pagination-total-text {
  position: absolute;
  left: 0px !important;
}

.wallet-inputfield {
  height: 50px;
  width: 130px;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 10px;
  display: flex;
  align-items: center;
  color: #0E730B;
  text-align: center;
}

.table-header {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}

.search-inputfield {
  width: 248px;
  height: 40px;
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid #b1b1b1;
  border-radius: 5px;
}

.search-inputfield .ant-input {
  background-color: #f0f0f0;
  color: #474747;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
}

.search-inputfield .ant-input:placeholder-shown {
  text-overflow: ellipsis;
  font-style: italic;
  color: #000000;
  background-color: #f0f0f0;
}

.pkr-div {
  color: #0E730B;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 10px;
  margin: 40px 0px 0px 10px;
}

.tenant-wallet-icon {
  margin: 10px 20px 0px 0px;
  width: 36.47px;
  height: 32.18px;
}

.arabic-fields .ant-input-affix-wrapper > input.ant-input {
  text-align: end;
}

.arabic-fields .site-form-item-icon {
  margin-right: 5px;
}

.date-of-birth {
  color: #696969;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
  margin-bottom: 15px;
}

.ant-picker-input > input {
  font-size: 16px;
}

.chart .completedVsTotal {
  font-family: "poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  margin: 10px 15px;

  color: #363636;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: right !important;
}

.gender-radio-div .ant-radio-group {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.gender-radio-div .ant-radio-group-solid > .ant-radio-button-wrapper {
  border-radius: 4px;
  width: 46%;
  color: #8c8c8c;
  height: 50px;
}

.gender-radio-div .ant-radio-button-checked {
  color: #ffffff !important;
  font-style: italic;
}

.gender-radio-div
  .ant-radio-group-solid
  > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #ffffff;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #045302;
  background-color: #ffffff;
  border: 1px solid #045302;
}

.ant-radio-button-wrapper-checked:hover {
  position: relative;
  background-color: #045302 !important;
}

.gender-radio-div .ant-radio-button-wrapper:not(:first-child):before {
  display: none;
}

.listing-edit-dropdown {
  padding: 5px 12px;
}

.editDropdown .ant-dropdown-menu-item {
  padding: 0px;
}
.inner-container-radio-gender {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
  font-size: 16px;
}

.gender-circle {
  width: 15px;
  height: 15px;
  margin-top: -17px;
}

.document-btn {
  width: 350px;
  height: 40px;
  background: #DDE9DC;
  border-radius: 6px;
  color: #0E730B;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-btn:hover {
  color: #0E730B;
  border-color: #0E730B;
  background: #fff;
}

.document-btn:focus {
  color: #1d4599;
  border-color: #40a9ff;
  background: #fff !important;
}
.ant-input-group-addon {
  background-color: #0E730B;
  width: 11%;
}

.arabic-fields .ant-input-group-wrapper {
  text-align: end;
}

.ant-upload.ant-upload-drag {
  background: none;
  border: none;
}

.main-edit-tenant-wallet {
  max-width: 70%;
  margin-left: 20px;
}

.main-edit-tenant-wallet .tenant-wallet-icon {
  margin: 0px;
}

.common-heading {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 10px;
  color: #000000;
}

.upload-document-btn {
  width: 85%;
  height: 40px;
  background: #0E730B;
  border-color: #0E730B;
  border-radius: 6px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
  color: #ffffff;
}

.upload-document-icon {
  height: 40px;
  background: #e1e9fa;
  border-radius: 6px;
}

.icon-upload-list {
  height: 14px;
  width: 14px;
  margin-right: 6px;
}

.upload-img-box {
  height: 150px;
  border-radius: 6px;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  text-align: center;
  padding: 5px 0px;
}

.rdp-table {
  margin: 0;
  max-width: calc(var(--rdp-cell-size) * 16);
  border-collapse: collapse;
}

.rdp-weeknumber,
.rdp-day {
  display: inline-table;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  margin: 0;
  border: 2px solid transparent;
  border-radius: 8%;
  background-color: #DDE9DC;
}

.rdp-months {
  display: block;
}

.rdp-day_selected:not([aria-disabled="true"]),
.rdp-day_selected:focus:not([aria-disabled="true"]),
.rdp-day_selected:active:not([aria-disabled="true"]),
.rdp-day_selected:hover:not([aria-disabled="true"]) {
  color: white;
  background-color: #0E730B;
}

.ant-tag-checkable-checked {
  background-color: #1d4599;
}

.custom-modal-footer .anticon svg {
  display: flex;
}

.specialInstructions .ant-popover-inner {
  width: 300px;
  height: auto;
  left: 479px;
  text-align: center;
  top: 307px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.ant-popover-inner {
  width: 175px;
  height: auto;
  left: 479px;
  top: 307px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.requests-card-heading {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.cancelBtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height */

  color: #ae0f0f;
}

.rescheduleBtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;
}

.selectTime-main {
  width: 100%;
  margin: 15px 0px;
}

.checkable-tag {
  width: 135px;
  height: 40px;
  text-align: center;
  text-align-last: center;
  font-size: 14px;
  margin: 6px;
  border-radius: 5px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}

.checkable-shift {
  width: 135px;
  height: 40px;
  text-align: center;
  text-align-last: center;
  font-size: 14px;
  margin: 10px 6px 10px 6px;
  border-radius: 5px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
}

.reschedule-image {
  width: 100px;
  border-radius: 10px;
  height: 75px;
}

.rdp-caption {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0;
  text-align: left;
}

.rdp-button:focus,
.rdp-button:active {
  color: inherit;
  border: 2px solid whitesmoke;
  background-color: var(--rdp-background-color);
}

.rdp-day_selected:focus:not([aria-disabled="true"]) {
  border: none;
}

.timeAvailability .ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 39px;
  height: 39px;
  direction: ltr;
  background-color: #DDE9DC;
  border: 1.3px solid #0E730B;
  border-radius: 6.5px;
  border-collapse: separate;
  transition: all 0.3s;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
  border-color: green;
}

.timeAvailability .ant-checkbox-checked .ant-checkbox-inner {
  background: #0E730B !important;
}

.timeAvailability .ant-checkbox-wrapper:hover .ant-checkbox:after,
.ant-checkbox:hover:after {
  visibility: hidden;
}

.timeAvailability .ant-checkbox-inner:after {
  position: absolute;
  top: 50%;
  left: 38.5%;
}

.slotAvailability .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border: 1px solid #9a9a9a;
  border-radius: 4px;
  top: 0;
  left: 20;
  display: block;
  direction: ltr;
  background-color: #e1e9fa;
  border-collapse: separate;
  transition: all 0.3s;
}

.slotAvailability .ant-checkbox-checked .ant-checkbox-inner {
  background: #0E730B !important;
}

.slotAvailability .slotChecks {
  width: 50%;
  align-items: center;
  text-transform: capitalize;
}

.slotAvailability .ant-checkbox + span {
  padding: 4px 8px 0px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #737373;
}

.slotAvailability .ant-form-item-explain-error {
  margin-top: 20px;
}

.slotAvailability .ant-checkbox-inner:after {
  position: absolute;
  top: 50%;
  left: 24.5%;
}

.slotAvailability .slotText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;

  color: #787878;
}

.ant-steps-item-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 7px;
  color: #0E730B;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #0E730B;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #0E730B;
}

.ant-upload-list-picture-card {
  margin-top: 20px;
}

.photosAndFacilities .ant-upload-list-picture-card {
  margin-top: 0px;
}

.ant-upload-list-picture-card-container {
  width: 150px;
  height: 150px;
}

.img-warn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  color: #9e9e9e;
  margin-bottom: 0px;
}

.filter {
  margin-right: 5px;
  height: 35px;
}

.edit-date-picker .ant-picker-clear {
  display: none;
}

.tenant-radio-group .ant-radio-button-wrapper:first-child {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
  border-radius: 5px 0px 0px 5px !important;
}

.tenant-radio-group .ant-radio-button-wrapper:last-child {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
  border-radius: 0px 5px 5px 0px !important;
}

.tenant-radio-group
  .ant-radio-group-solid
  > .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  color: #fff;
  background: #0E730B;
  border-color: #0E730B;
  border-radius: 5px 0px 0px 5px !important;
}

.tenant-radio-group
  .ant-radio-group-solid
  > .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):last-child {
  color: #fff;
  background: #0E730B;
  border-color: #0E730B;
  border-radius: 0px 5px 5px 0px !important;
}

.tenant-radio-group .ant-radio-button-wrapper:not(:first-child):before {
  display: none;
}

.no-data-found {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-found-card {
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn .anticon.anticon-plus > svg {
  height: 0.9rem;
}

.timeAvailability .ant-form-item-control-input-content span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #000000 !important;
  margin-bottom: 8px;
}

.timeAvailability .daysCheckboxParent {
  width: 50%;
  align-items: center;
  display: flex;
}

.timeAvailability .daysCheckbox {
  width: 50%;
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
}

.slot-available {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
  color: #040404;
}

.long-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #707174;
  opacity: 0.74;
  background-color: #eaeaea;
  overflow: hidden;
  padding: 6px 15px;
  height: 40px;
  border-radius: 6px;
  max-width: 250px;
}

.ant-modal-footer {
  .ant-btn-primary {
    background-color: #0E730B;
    border-color: #0E730B;
  }
  .ant-btn-primary:hover {
    background-color: #045302;
  }

  .ant-btn-default:hover {
    background: #DDE9DC;
    border: 1px solid #d9d9d9;
    color: grey;
  }
}

.ant-modal-header {
  border-radius: 6px;
}

.ant-modal-content {
  border-radius: 6px;
}

.ant-modal-footer .ant-btn {
  border-radius: 6px;
}

.inputfield-sub-line {
  margin-top: -20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: rgba(133, 133, 133, 0.8);
}

.add-service-image .ant-upload.ant-upload-select-picture-card {
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  background: #e1e9fa;
  border: 2px dashed #1d4599;
  border-radius: 14px;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  margin-top: 0px;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  background: #e1e9fa;
  border: 2px dashed #1d4599;
  height: 150px;
  border-radius: 14px;
  width: 150px;
}

.status-bar {
  background: rgba(43, 158, 75, 0.2);
  border-radius: 5px;
  height: 38px;
  padding: 11px 24px;
}

.status-bar-grey {
  background: rgba(222, 226, 223, 0.822);
  border-radius: 5px;
  height: 38px;
  padding: 11px 24px;
}

.status-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.parcel-pd {
  padding: 20px 40px;
}

.parcel-img-view {
  height: 80px;
  width: 80px;
  border-radius: 6px;
}

.parcel-heading-in-view {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
}

.tenantDetails {
  font-family: "Roboto";
}

.tenantDetails .mainHeading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #363636;
}

.tenantDetails .subHeadings {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 10px;
  align-items: center;
  color: #040404;
}

.tenantDetails .preferredText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;
}

.tenantDetails .dateAndTime {
  width: 125px;
  height: 82px;
  background: #0E730B;
  border-radius: 6px;
  text-align: center;
  color: white;
  padding: 5px;
}

.tenantDetails .activeShift {
  padding: 10px;
  margin: 6px;
  width: 60px;
  text-align: center;
  background: #0E730B;
  border-radius: 5px;
  font-size: 16px;
  border: 1px solid rgba(152, 152, 152, 0.172968);
  font-weight: 500;
  color: #ffffff;
}

.tenantDetails .inactiveShift {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  color: #000000;
  opacity: 0.25;
}

.tenantDetails .bookedTime {
  width: 225px;
  height: 65px;
  background: #0E730B;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  text-align: center;

  color: #ffffff;
}

.staticStatus
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.tenantDetails .purposeBtn {
  background: #0E730B;
  mix-blend-mode: normal;
  border-radius: 5px;
  min-width: 99px;
  max-width: 345px;
  padding: 10px;
  white-space: pre-wrap;
  min-height: 40px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.tenantDetails .detailsTableHeading {
  text-align-last: left;
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #000000;
}

.tenantDetails .detailsTableCols {
  text-align-last: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #8c8c8c;

  opacity: 0.79;
}

.tenantDetails .TableCols {
  min-width: 20%;
  width: 200px;
  font-size: 16px;
}

.parcel-hash-no-in-view {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  color: #8c8c8c;
}

.parcel-description-in-view {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #363636;
  margin-top: -5px;
}

.received-on-in-parcel-view {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}

.received-on-date-in-parcel-view {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.amount-in-parcel-view {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #1d4599;
  text-align: right;
}

.heading-in-parcel-view {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
  display: flex;
  align-items: center;
  color: #040404;
}

.selected-radio-btn {
  color: #0e3dcb !important;
}

.payment-method {
  width: 60%;
  margin-top: 2rem;
}

.payment-method .ant-radio-group {
  display: flex;
  justify-content: space-between;
}

.payment-method .ant-radio-button-wrapper {
  width: 49%;
  height: 60px;
  border-radius: 8px !important;
  background-color: #ffffff;
  color: #000000;
}

.payment-method .ant-radio-button-wrapper:not(:first-child):before {
  display: none;
}

.payment-method
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #ffffff;
  border: 1.5px solid #1d4599;
  color: #0e3dcb !important;
}

.payment-circle {
  height: 22px;
  width: 22px;
  margin-top: 10px;
}

.E-butler-balance {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
  text-align: start;
}

.E-butler-balance-description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
  text-align: start;
}

.E-butler-balance-description-grey {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
  color: #8c8c8c;
  text-align: start;
}

.payment-receiving-options {
  width: 90%;
  margin-top: 3rem;
}

.payment-receiving-options .ant-radio-group {
  display: flex;
  justify-content: space-between;
}

.payment-receiving-options .ant-radio-button-wrapper {
  width: 32.5%;
  height: 60px;
  border-radius: 8px !important;
  background-color: #ffffff;
  color: #000000;
}

.payment-receiving-options .ant-radio-button-wrapper:not(:first-child):before {
  display: none;
}

.payment-receiving-options
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: #ffffff;
  border: 1.5px solid #1d4599;
  color: #0e3dcb !important;
}

.parcel-person-detail {
  margin-top: 3rem;

  h1 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 10px;
    color: #040404;
  }

  h2 {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8c8c8c;
    opacity: 0.79;
  }
}

.organization-setting-banner .ant-upload {
  height: 120px;
  width: 330px;
}

.organization-setting-banner .ant-upload-list-item-list-type-picture-card {
  height: 120px;
  width: 330px;
  border-radius: 8px;
}

.ant-upload-list-picture-card .ant-upload-list-item-info,
.ant-upload-list-picture .ant-upload-list-item-info {
  padding: 0px;
  border-radius: 12px;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th,
.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th {
  padding: 16px 10px;
}

.disable-email .ant-input-disabled {
  color: #8c8c8c;
}

.preview-only .ant-input-disabled {
  color: #8c8c8c;
}

.first-step-bottom-div {
  margin-bottom: 0px;
  justify-content: space-between;
}

.ant-popover-title {
  font-size: 15px;
  border-bottom: none;
}

.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  text-transform: uppercase;
}

.filterButtons {
  display: flex;
}
.date-filter {
  padding: 0px 5px;
  margin-bottom: 1px;
  margin-left: 5px;
}

.filter-datePicker {
  border-radius: 5px;
  width: 164px;
  height: 40px;

  background: rgba(0, 0, 0, 0.06);
  border: 1px solid #b1b1b1;
  border-radius: 5px;
}
.date-cross-btn {
  width: 20px;
  text-align-last: center;
  cursor: pointer;
  color: white;
  border-radius: 47px;
  height: 20px;
  margin: 1px auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #fff !important;
  border-bottom: none;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__navigation-icon {
  margin-top: 12px;
}

.react-datepicker {
  font-size: 0.8rem;
  width: 310px;
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.react-datepicker__current-month {
  color: #1d4599;
}

.react-datepicker__day-names {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  text-align: center;
  letter-spacing: -0.078px;

  color: rgba(60, 60, 67, 0.3);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.3rem;
  line-height: 1.9rem;
}

.ant-badge-status-processing {
  background-color: #1d4599;
}

.ant-badge {
  align-self: center;
}

.margin-left-15 {
  margin-left: 15px;
}

.ant-layout-sider {
  flex: 0 0 17% !important;
  max-width: 17% !important;
  min-width: 17% !important;
  width: 17% !important;
}

.ant-layout-sider-children {
  position: fixed;
  width: 17%;
}

.side-bar-noti-count {
  background-color: #1d4599;
  color: #ffffff;
  padding: 8px 10px;
  margin: 14px;
  border-radius: 6px;
}

.ant-drawer-header {
  display: block;
}
.ant-drawer-close {
  display: none;
}

.barIcons {
  height: 1rem;
}

.headerIcons {
  height: 1.5rem;
  color: #474747;
}

.bell-blue-dot {
  height: 1.8rem;
}
.csv-btn {
  width: 220px;
  margin-right: 10px;
  background: #d0f4d9;
  color: #3d9611;
  justify-content: center;
  font-family: "Roboto";
  font-weight: 500;
  text-shadow: none;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
}
.csv-btn:focus {
  background: #d0f4d9 !important;
  color: #3d9611;
  border-color: #d0f4d9;
}
.csv-btn:hover {
  background: #d0f4d9;
  color: #3d9611;
  border: 1px solid #3d9611;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-radius: 6px;
  width: 100%;
  height: 50px;
  padding: 15px !important;
  border: 1px solid #3d9611;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #3d9611;
}

.ant-menu-dark .ant-menu-item {
  width: 100%;
  height: 50px;
  padding: 15px !important;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #888888;
}

.ant-menu-dark .ant-menu-item:hover {
  background-color: #0E730B1A;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #888888;
  border-radius: 6px;
  width: 100% !important;
  height: 50px;
  padding: 15px !important;
}

.ant-menu-dark .ant-menu-submenu-title:hover {
  background-color: #0E730B1A;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #888888;
  border-radius: 6px;
  height: 50px;
  padding: 15px !important;
}

.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  border-radius: 6px;
  width: 90%;
  margin-left: 13px;
  height: 50px;
  padding: 15px !important;
  color: #888888;
}

.ant-menu-dark .ant-menu-submenu-selected {
  background-color: #0E730B1A;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0E730B !important;
  border-radius: 6px;
  margin: 0px 5px;

  .ant-menu-submenu-title {
    color: #0E730B;
  }
}

.ant-menu-dark
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow:before {
  background: #0E730B !important;
}

.ant-menu-dark
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow:after {
  background: #0E730B !important;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background: #888888;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #888888;
}

.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  overflow: visible;
  text-overflow: initial;
}

.ant-menu-dark .ant-menu-submenu-title:hover {
  background-color: #0E730B1A;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0E730B;
  border-radius: 6px;
  width: 96%;
  right: 8px;
  height: 50px;
  padding: 15px !important;
}

.guidelines-modal {
  padding: 30px;
}

.blue-border {
  border: 1.5px solid #1d4599;
}

.grey-border {
  border: 1.5px solid #d9d9d9;
}

.un_read_noti {
  height: 7px;
  margin: 0px 0px 25px -5px;
}

.add-more-btn {
  background: #0E730B;
  border-radius: 8px;
  width: 108px;
  height: 50px;
  color: #ffffff;
  border-color: #0E730B;
}

.ant-modal-close {
  display: none;
}

.crossIcon {
  height: 13px;
  width: 13px;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item {
  width: 90%;
}

.ant-drawer .ant-drawer-content {
  background: #f9f9f9;
}

.menu-drawer-btn {
  display: none;
}

.drawer-header-ul {
  align-items: center;
  margin-bottom: unset;
    margin-top: -23px;
}

.small-btn {
  display: none;
}

@media (max-width: 425px) {
  .img-preview-modal .ant-modal-content {
    width: 372px;
    .ant-modal-body > img {
      width: 325px !important;
    }
  }
  .modal-btn {
    width: 100%;
  }

  .modal-btn-wth-upload {
    width: 50%;
  }

  .main-edit-tenant-wallet {
    max-width: 100%;
    margin-left: 0px;
  }

  .site-layout-background-content {
    margin: 10px 10px;
    padding: 0px;
  }

  .gender-radio-div .ant-radio-group {
    width: 100%;
  }
  .am-and-pm-div {
    width: 196px;
  }
}

@media (max-width: 980px) {
  .mobile-preview {
    margin-top: 15px !important;
  }

  .ant-layout-sider {
    display: none;
  }

  .nav-links-header {
    display: none;
  }

  .menu-drawer-btn {
    display: block;
  }

  .header-heading {
    padding-top: 10px;
    align-items: center;
  }

  .table-pagination {
    display: block;

    .ant-pagination-item-active {
      display: block !important;
    }

    .ant-pagination-total-text {
      display: none;
    }

    .ant-select-selector {
      display: none;
    }

    .ant-select-arrow {
      display: none;
    }

    .ant-pagination-item {
      display: none;
    }

    .ant-pagination-jump-next-custom-icon {
      display: none;
    }
  }

  .table-pagination {
    width: 100%;
  }

  .payment-method {
    width: 100%;
  }

  .payment-method .ant-radio-button-wrapper {
    width: 100%;
    height: 70px;
    margin-top: 15px;
  }

  .payment-method .ant-radio-group {
    display: block;
  }

  .payment-receiving-options {
    width: 100%;
  }

  .payment-receiving-options .ant-radio-button-wrapper {
    width: 100%;
    height: 70px;
    margin-top: 15px;
  }

  .payment-receiving-options .ant-radio-group {
    display: block;
  }

  .parcel-pd {
    padding: 0px;
  }
}

@media (min-width: 980px) {
  .ant-drawer-content-wrapper {
    display: none;
  }
  .ant-table .ant-table-container:after {
    display: none;
  }
}

@media (max-width: 445px) {

  .notification-dropdown-menu-item{
    width: 330px;
    margin-left: 15px;
  }
  .steps-action {
    right: 0px;
    bottom: 10px;
  }

  .stepNumber {
    bottom: 10px;
  }

  .filter-datePicker {
    width: 150px;
  }

  .search-inputfield {
    width: 150px;
  }
  .photosAndFacilities .ant-form-item-control-input-content {
    margin-bottom: 10%;
  }

  .timeAvailability .daysCheckbox {
    width: 22%;
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
  }

  .timeAvailability .ant-checkbox-inner {
    width: 33px;
    height: 33px;
  }

  .steps-content {
    padding: 10px 10px;
    width: 100% !important;
  }

  .checkable-tag {
    width: 44%;
  }

  .checkable-shift {
    width: 93%;
  }

  .slotAvailability .slotChecks {
    width: 42%;
    align-items: right;
  }

  .site-layout .site-layout-background-inner {
    margin: 5px 5px;
    padding: 5px;
    min-height: 280;
  }

  .banner-main {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }

  .ant-card-bordered {
    width: 100% !important;
  }

  .ant-card-body {
    padding: 15px;
  }

  .main-edit-tenant-wallet {
    max-width: 100%;
    margin-left: 10px;
  }

  .table-pagination {
    display: block;
  }

  .large-btn {
    display: none;
  }

  .small-btn {
    display: block !important;
  }

  .table-header {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
  }

  .tenantDetails .bookedTime {
    width: 190px;
  }

  .maintenanceListing .ant-row {
    justify-content: center;
  }
}

@media (max-width: 680px) {
  .dp-grid-tenant {
    display: block;
  }
  .tenant-radio-group {
    display: block !important;
  }
  .ant-radio-group {
    display: flex;
  }
  .servicesMainBtn {
    width: auto;
    height: 40px;
  }

  .requests-card-heading {
    display: block;
  }

  .servicesBackBtn {
    width: auto;
    height: 40px;
  }

  nav {
    padding: 0px 15px;
  }

  .document-btn {
    width: 100%;
  }

  .Services-Input .ant-radio-button-wrapper {
    color: #1d4599;
    font-size: 13px;
    margin-left: 10px;
    line-height: normal;
  }

  .rdp-caption {
    justify-content: space-between;
  }

  .selectTime-main {
    width: 100%;
  }

  .filterButtons {
    margin: 3px 0px 0px 0px;
  }

  .filter-datePicker {
    width: 180px;
  }

  .search-inputfield {
    width: 180px;
  }

  .react-datepicker {
    width: 275px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2rem;
    line-height: 1.9rem;
  }
  .date-filter {
    padding: 0px 2px;
    margin-bottom: 1px;
    margin-left: 1px;
  }
  .date-cross-btn {
    border-radius: 20px;
  }
}

@media (max-width: 768px) {
  .table-pagination {
    width: auto;
  }

  .small-btn {
    display: none;
  }
}

@media (min-width: 680px) and (max-width: 1024px) {
  .dp-grid-tenant {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }
  .tenant-radio-group {
    display: flex !important;
    justify-content: space-between;
  }

  .search-inputfield {
    width: 150px;
  }
}

@media (max-width: 410px) {
  .img-preview-modal .ant-modal-content {
    width: 325px;
    .ant-modal-body > img {
      width: 280px !important;
    }
  }

  .filter-datePicker {
    width: 150px;
  }

  .search-inputfield {
    width: 150px;
  }
  .am-and-pm-div {
    width: 196px;
  }
}

@media (max-width: 350px) {
  .notification-dropdown-menu-item{
    width: 300px;
    margin-left: 10px;
  }
  .img-preview-modal .ant-modal-content {
    width: 290px;
    .ant-modal-body > img {
      width: 250px !important;
    }
  }

  .filter-datePicker {
    width: 140px;
  }

  .search-inputfield {
    width: 120px;
  }
  .organization-setting-banner .ant-upload {
    width: 250px;
  }
  .organization-setting-submit {
    margin-right: 5px;
  }

  .organization-setting-banner .ant-upload-list-item-list-type-picture-card {
    width: 250px;
  }

  .am-and-pm-div {
    width: 196px;
  }
}


.ant-spin-dot-item {
  background-color: #34C759;
}