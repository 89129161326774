$font: 14px;
$padding: 8px;
$border-radius: 5px;

%input {
    box-sizing: border-box;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #CBCBCB;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 5px;

}